import "./Footer.css";

const Footer = () => {
  return (
    <div>
      <p id="footer">Copyright &#64; 2022 Sabrina Angelle</p>
    </div>
  );
};

export default Footer;
